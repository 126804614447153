import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const data01 = [
  {
    name: "Incomes",
    amount: 2600,
  },
  {
    name: "Outcomes",
    amount: 1398,
  }
];

export default function VerticalBarChart({data}) {
  const exposedData = data01;

  return (
    <ResponsiveContainer width='100%' aspect={4.0/1.0}>
      <BarChart
      height={100}
      data={exposedData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      layout='vertical'
      >
        <YAxis type="category" dataKey="name" />
        <XAxis type="number" />
        <Tooltip />
        <Bar dataKey="amount" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}
