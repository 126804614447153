import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const categoryData = [
  {name: "January", incomes: 342.06, outcomes: 551.12},
  {name: "February", incomes: 251.55, outcomes: 2200},
  {name: "March", incomes: 2200, outcomes: 550},
  {name: "April", incomes: 2510.55, outcomes: 1410},
  {name: "May", incomes: 2121, outcomes: 1241}
]

export default function CategoriesBarChart({data}) {
  const exposedData = data ?? categoryData;

  return (
    <ResponsiveContainer width='100%' height={468}>
      <BarChart
        width={500}
        height={300}
        data={exposedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="incomes" fill="#8884d8" />
        <Bar dataKey="outcomes" fill="coral" />
      </BarChart>
    </ResponsiveContainer>
  );
}
