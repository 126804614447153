import * as React from 'react';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, Divider } from "@mui/material";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Add, AutoGraph, Close, FormatListBulleted, ShoppingCart } from '@mui/icons-material';
import { Link, useParams, useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id, date, title, category, recipient, amount) {
  return { id, date, title, category, recipient, amount };
}

export default function TransactionsPage() {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [monthTransactions, setMonthTransactions] = React.useState([]);
  

  let { date } = useParams();

  React.useEffect(() => {
    setMonthTransactions([]);
  }, []);

  return (
    <>
      <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={() => {setIsDrawerOpen(true)}}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MoneyTrack
            </Typography>
            <Link>
              <Button onClick={() => {
                navigate("/login");
              }}>Logout</Button>
            </Link>
            </Toolbar>
          </AppBar>
        </Box>

        <SwipeableDrawer anchor='left' open={isDrawerOpen} onOpen={()=>{setIsDrawerOpen(true)}} onClose={()=>{setIsDrawerOpen(false)}}>
          <Box p={2} width={26 + 'rem'} textAlign={'center'} role='presentation'>
            <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 + '!important', mb: 4 }}>
              <Typography variant='h5' component='div'>
                MoneyTrack side panel
              </Typography>
              <Typography variant='h5' component='div'>
                <Button onClick={() => {setIsDrawerOpen(false)}}><Close/></Button>
              </Typography>
            </Container>

            <Link to={`/create-expense/${date}`}>
              <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                <Add/>Add expense
              </Button>
            </Link>
            <Divider/>

            <Link to={`/dashboard/${date}`}>
              <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                <FormatListBulleted/>Home
              </Button>
            </Link>

            <Link to={`/transactions/${date}`}>
              <Button variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                <ShoppingCart/>Transactions
              </Button>
            </Link>

            <Link to={`/summary/${date}`}>
              <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                <AutoGraph/>Summary
              </Button>
            </Link>
          </Box>
        </SwipeableDrawer>
      </Container>

      <Container maxWidth="lg" sx={{ py: 1 }}>
        <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1 }}>
          <Typography variant='h4' component='div'>
              This month transactions
          </Typography>
          <Divider/>

          {monthTransactions.map((category) => {
            const transactions = [];
            category.transactions.forEach(element => {
              transactions.push(createData(element.id, element.date, element.title, category.name, element.recipient, element.amount));
            });

            if(transactions.length !== 0)
            {
              return (
                <Container key={category.name}>
                  <Typography variant='h5' component='div' sx={{ py: 2 }}>
                    {category.name}
                  </Typography>
  
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Date</StyledTableCell>
                        <StyledTableCell align="right">Title</StyledTableCell>
                        <StyledTableCell align="right">Category</StyledTableCell>
                        <StyledTableCell align="right">Recipient</StyledTableCell>
                        <StyledTableCell align="right">Amount</StyledTableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {transactions.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell component="th" scope="row">
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell align="right">{row.title}</StyledTableCell>
                          <StyledTableCell align="right">{row.category}</StyledTableCell>
                          <StyledTableCell align="right">{row.recipient}</StyledTableCell>
                          <StyledTableCell align="right">{(row.amount/100)}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
              )
            }
            else
            {
              return ("");
            }
          })}
        </Container>
      </Container>
    </>
  );
}
