import * as React from 'react';
import { Container, Stack, Typography, Skeleton } from '@mui/material';
import { Cart4 } from 'react-bootstrap-icons';
import Grid from '@mui/material/Grid';

export default function TransactionItem({name, amount, transactionType, date}) {
    return (
        <Grid container sx={{ alignItems: 'center', p: 2, my: 2, borderRadius: 2, backgroundColor: '#1f2937', border: '1px darkgrey solid' }}>
            <Grid item md={1}>
                <Container sx={{ display: 'flex', justifyContent: 'center', width: 40, p: 0 + '!important' }}>
                    <Cart4 size={24}/>
                </Container>
            </Grid>
            <Grid item md={8}>
                <Container>
                    <Stack>
                        <Typography variant='p' sx={{ fontWeight: 600 }}>{
                            name ? name : <Skeleton variant="rounded" height={24} />
                        }</Typography>
                        <Typography variant="body2" color="text.secondary">{date}</Typography>
                    </Stack>
                </Container>
            </Grid>
            <Grid item md={3}>
                <Container sx={{ p: 0 + '!important' }}>
                    {transactionType === "expense" ? '- ' : '+ '}{(amount / 100).toLocaleString("pl-PL") + ' PLN'}
                </Container>
            </Grid>
        </Grid>
    )
};