import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, Divider } from "@mui/material";
import TransactionItem from "../components/TransactionItem/TransactionItem";
import VerticalBarChart from "../components/Charts/VerticalBarChart";
import PieChart from "../components/Charts/PieChart";
import { Add, AutoGraph, Close, FormatListBulleted, ShoppingCart } from '@mui/icons-material';
import { Link, useParams, useNavigate } from 'react-router-dom';

const last5Transactions = [
    {id: 1, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 2, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 3, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 4, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 5, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"}
];

export default function DashboardPage() {
  const navigate = useNavigate();
  
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  let { date } = useParams();

  return (
    <>
        <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => {setIsDrawerOpen(true)}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        MoneyTrack
                    </Typography>
                    <Link>
                        <Button onClick={() => {
                            navigate("/login");
                        }}>Logout</Button>
                    </Link>
                    </Toolbar>
                </AppBar>
            </Box>

            <SwipeableDrawer anchor='left' open={isDrawerOpen} onOpen={()=>{setIsDrawerOpen(true)}} onClose={()=>{setIsDrawerOpen(false)}}>
                <Box p={2} width={26 + 'rem'} textAlign={'center'} role='presentation'>
                    <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 + '!important', mb: 4 }}>
                        <Typography variant='h5' component='div'>
                            MoneyTrack side panel
                        </Typography>
                        <Typography variant='h5' component='div'>
                            <Button onClick={() => {setIsDrawerOpen(false)}}><Close/></Button>
                        </Typography>
                    </Container>

                    <Link to={`/create-expense/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <Add/>Add expense
                        </Button>
                    </Link>

                    <Divider/>

                    <Link to={`/dashboard/${date}`}>
                        <Button variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                            <FormatListBulleted/>Home
                        </Button>
                    </Link>

                    <Link to={`/transactions/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <ShoppingCart/>Transactions
                        </Button>
                    </Link>

                    <Link to={`/summary/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <AutoGraph/>Summary
                        </Button>
                    </Link>
                </Box>
            </SwipeableDrawer>
        </Container>

        <Container maxWidth="lg" sx={{ py: 1 }}>
            <Grid container spacing={0} >
                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>
                        {last5Transactions.map((item) => {
                            return (
                                <TransactionItem key={item.id} name={item.title} amount={item.amount} transactionType={item.transaction_type} date={item.date} />
                            )
                        })}
                    </Container>
                </Grid>

                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>

                        <Container>
                            <VerticalBarChart data={null} />
                        </Container>
                        <Divider sx={{ m: 1}} />
                        <Container sx={{ display: 'flex', justifyContent: 'center', height: 520 }}>
                            <PieChart data={null}/>
                        </Container>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    </>
  );
}
