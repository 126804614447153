import React from "react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";

const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 }
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function CategoriesPieChart({data}) {
  const exposedData = data01;
  
  return (
    <PieChart width={400} height={400}>
      <Pie
        data={exposedData}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={70}
        outerRadius={90}
        fill="#82ca9d"
        label
      >
      {exposedData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
      </Pie>
      <Tooltip />
      <Legend height={36}/>
    </PieChart>
  );
}

