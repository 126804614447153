import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { LoginSharp } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

function getCurrentDate()
{
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;

    return currentDate;
}

export default function LoginPage() {
    const navigate = useNavigate();

    const currentDate = getCurrentDate();   

    const handleSubmit = (event) => {
        event.preventDefault();
        
        navigate(`/dashboard/${currentDate}`);
    }

    React.useEffect(() => {

    }, []);

  return (
    <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
    <Grid container spacing={0} sx={{ height: 100 + 'vh' }}>

        <Grid item xs={0} md={6} sx={{ height: 'inherit' }}>
            <Box 
                sx={{ 
                    bgcolor: 'rgb(2,0,36)',
                    background: 'linear-gradient(320deg, rgba(2,0,36,1) 0%, rgba(121,9,118,1) 35%, rgba(145,0,255,1) 100%)',
                    height: '100vh' 
                }} 
            />
        </Grid>

        <Grid item xs={12} md={6} sx={{ height: 'inherit' }}>
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit'}}>
                <Stack spacing={2}>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} sx={{mb: 2}}>
                            <TextField id="login" name="login" label="Login" variant="outlined" />
                            <TextField id="password" name="password" label="Password" variant="outlined" type='password' />
                        </Stack>
                        {/* <Link to={'/dashboard'}> */}
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                <LoginSharp/> Login
                            </Button>
                        {/* </Link> */}
                    </form>
                </Stack>
            </Container>
        </Grid>
        
    </Grid>
    </Container>
  );
}
