import * as React from 'react';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, Grid, Divider } from "@mui/material";
import PieChart from "../components/Charts/PieChart"
import CategoriesBarChart from '../components/Charts/CategoriesBarChart';

import { Add, AutoGraph, Close, FormatListBulleted, ShoppingCart } from '@mui/icons-material';
import { Link, useParams, useNavigate } from 'react-router-dom';

const valuesMappedByCategories = [
  {name: "Groceries", value: 100}
]

export default function SummaryPage() {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  let { date } = useParams();

  React.useEffect(() => {

  }, []);

  return (
    <>
      <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
          <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                  <Toolbar>
                      <IconButton onClick={() => {setIsDrawerOpen(true)}}
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          sx={{ mr: 2 }}
                      >
                          <MenuIcon />
                      </IconButton>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    MoneyTrack
                  </Typography>
                  <Link>
                    <Button onClick={() => {
                      navigate("/login");
                    }}>Logout</Button>
                  </Link>
                  </Toolbar>
              </AppBar>
          </Box>

          <SwipeableDrawer anchor='left' open={isDrawerOpen} onOpen={()=>{setIsDrawerOpen(true)}} onClose={()=>{setIsDrawerOpen(false)}}>
              <Box p={2} width={26 + 'rem'} textAlign={'center'} role='presentation'>
                <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 + '!important', mb: 4 }}>
                  <Typography variant='h5' component='div'>
                    MoneyTrack side panel
                  </Typography>
                  <Typography variant='h5' component='div'>
                    <Button onClick={() => {setIsDrawerOpen(false)}}><Close/></Button>
                  </Typography>
                </Container>

                <Link to={`/create-expense/${date}`}>
                  <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                    <Add/>Add expense
                  </Button>
                </Link>
                <Divider/>

                <Link to={`/dashboard/${date}`}>
                  <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                    <FormatListBulleted/>Home
                  </Button>
                </Link>

                <Link to={`/transactions/${date}`}>
                  <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                    <ShoppingCart/>Transactions
                  </Button>
                </Link>

                <Link to={`/summary/${date}`}>
                  <Button variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                    <AutoGraph/>Summary
                  </Button>
                </Link>
              </Box>
            </SwipeableDrawer>
      </Container>

      <Container maxWidth="lg" sx={{ py: 1 }}>
        <Grid container spacing={0} >

          <Grid item xs={12} md={6} sx={{ height: 'inherit', mt: 2 }} px={{ sm: 2}}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, minHeight: 580 }}>
                <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                    Your last transactions
                </Typography>
                {valuesMappedByCategories.map((element) => {
                    return (
                      <Container sx={{ p: 0 + '!important' }} key={element.name}>
                        <Container sx={{ display: 'flex', justifyContent: 'space-between' }} >
                          <div>{element.name}</div>
                          <div>{element.value}</div>
                        </Container>
                        <Divider sx={{ m: 1}} />
                      </Container>
                    )
                  })}
            </Container>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: 'inherit', mt: 2 }} px={{ sm: 2}}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, minHeight: 580 }}>
              Expenses by category
              <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <PieChart/>
              </Container>
            </Container>
          </Grid>

          <Grid item xs={12} md={12} sx={{ height: 'inherit', mt: 2}} px={{ sm: 2 }}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, width: 100 + '%' }}>
              Expenses by category
              <CategoriesBarChart/>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
