import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, TextField, Divider } from "@mui/material";
import TransactionItem from "../components/TransactionItem/TransactionItem";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add, AutoGraph, Close, FormatListBulleted, ShoppingCart } from '@mui/icons-material';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { InputLabel, MenuItem, FormControl, Select, Alert } from '@mui/material';

const windowWidth = window.innerWidth;

const last5Transactions = [
    {id: 1, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 2, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 3, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 4, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"},
    {id: 5, title: "test", amount: 12, transactionType: "expense", date: "2023-09-12"}
];

const categories = [
    {id: 1, category_name: "groceries"}
];

export default function CreateExpensePage() {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [age, setAge] = React.useState('');

  const [formData, setFormData] = React.useState({title: "", date: "", category_id: "", recipient: "", amount: ""});
  const [alert, setAlert] = React.useState({message: "", class: ""});

  let { date } = useParams();

  const handleChange = (event) => {
    setAge(event.target.value);

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleDatePickerChange = (date) => {
    var dateToSave = new Date(Date.parse(date["$d"]));
    dateToSave = dateToSave.getFullYear() + '-' + (dateToSave.getMonth() + 1) + '-' + dateToSave.getDate();

    var copiedFormData = formData;
    copiedFormData.date = dateToSave;
    setFormData(copiedFormData);
  };
  
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    formData.amount = formData.amount * 100;

    setAlert({message: `Transaction ${formData.title} saved to database!`, class: 'success'});

    setAge("");
    setFormData({title: "", date: formData.date, category_id: "", recipient: "", amount: ""});
    
    setTimeout(() => {
        setAlert({message: null, class: null});
    }, 3000)
  }

  React.useEffect(() => {

  }, []);

  return (
    <>
        <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => {setIsDrawerOpen(true)}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        MoneyTrack
                    </Typography>
                    <Link>
                        <Button onClick={() => {
                            navigate("/login");
                        }}>Logout</Button>
                    </Link>
                    </Toolbar>
                </AppBar>
            </Box>

            <SwipeableDrawer anchor='left' open={isDrawerOpen} onOpen={()=>{setIsDrawerOpen(true)}} onClose={()=>{setIsDrawerOpen(false)}}>
                <Box p={2} width={26 + 'rem'} textAlign={'center'} role='presentation'>
                    <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 + '!important', mb: 4 }}>
                        <Typography variant='h5' component='div'>
                            MoneyTrack side panel
                        </Typography>
                        <Typography variant='h5' component='div'>
                            <Button onClick={() => {setIsDrawerOpen(false)}}><Close/></Button>
                        </Typography>
                    </Container>

                    <Link to={`/create-expense/${date}`}>
                        <Button variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                            <Add/>Add expense
                        </Button>
                    </Link>

                    <Divider/>

                    <Link to={`/dashboard/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <FormatListBulleted/>Home
                        </Button>
                    </Link>

                    <Link to={`/transactions/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <ShoppingCart/>Transactions
                        </Button>
                    </Link>

                    <Link to={`/summary/${date}`}>
                        <Button variant="outlined" color="primary" fullWidth sx={{ my: 1 }}>
                            <AutoGraph/>Summary
                        </Button>
                    </Link>
                </Box>
            </SwipeableDrawer>
        </Container>

        {alert.message && windowWidth < 600 ?
            <Container maxWidth="sm" sx={{ mt:2 }}>
                <Alert variant="outlined" severity={alert.class} onClose={() => {setAlert({message: null, class: null})}}>
                    {alert.message}
                </Alert>
            </Container>
            : ''
        }

        <Container maxWidth="lg" sx={{ py: 1 }}>
            <Grid container spacing={0} >
                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1 }}>
                        <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                            Add Expense
                        </Typography>

                        <Container>
                            <form onSubmit={handleSubmit}>
                                <TextField id="title" name="title" label="Title" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.title} onChange={handleFormChange} required/>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        id="date" name="date"
                                        label="Date"
                                        sx={{ width: 100 + '%', my: 1 }}
                                        onChange={handleDatePickerChange}
                                    />
                                </LocalizationProvider>

                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="category_id" name="category_id"
                                        value={age}
                                        label="Category"
                                        onChange={handleChange}
                                        required
                                    >
                                        {categories.map((category) => {
                                            return (
                                                <MenuItem value={category.id} key={category.id}>{category.category_name}</MenuItem>
                                            )
                                        })};
                                    </Select>
                                </FormControl>

                                <TextField id="recipient" name="recipient" label="Recipient" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.recipient} onChange={handleFormChange}/>

                                <TextField id="amount" name="amount" type="number" inputProps={{ step: 0.01 }} label="Amount" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.amount} onChange={handleFormChange} required/>

                                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                                    Save
                                </Button>
                            </form>
                        </Container>
                    </Container>
                </Grid>

                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>
                        {last5Transactions.map((item) => {
                            return (
                                <TransactionItem key={item.id} name={item.title} amount={item.amount} transactionType={item.transaction_type} date={item.date} />
                            )
                        })}
                    </Container>
                </Grid>
            </Grid>
        </Container>

        {alert.message && windowWidth > 600 ?
            <Container maxWidth="sm" sx={{ position: 'fixed', bottom: 60, right: 30 }}>
                <Alert variant="outlined" severity={alert.class} onClose={() => {setAlert({message: null, class: null})}}>
                    {alert.message}
                </Alert>
            </Container>
            : ''
        }
    </>
  );
}
